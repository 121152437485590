import { makeStyles } from "tss-react/mui";

export const useAuthorSiteTemplateStyles = makeStyles()(() => ({
  marginLeft: {
    marginLeft: "10px",
  },
  autorDescription: {
    width: "40%",
    "@media (max-width: 600px)": {
      width: "60%",
    },
  },
  marginRight: {
    marginRight: "10px",
  },
}));
