import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Avatar, Box, Grid, Link as MuiLink, Typography } from "@mui/material";
import { graphql, Link as GatsbyLink } from "gatsby";
import { SeoBasics } from "../../01-atoms/SeoBasics/SeoBasics";
import { AuthorSiteTemplateProps } from "./AuthorSiteTemplateInterfaces";
import { useAuthorSiteTemplateStyles } from "./AuthorSiteTemplateStyles";

export const query = graphql`
  query ($id: String!) {
    users: allWpUser(filter: { id: { eq: $id } }) {
      nodes {
        id
        firstName
        name
        lastName
        description
        avatar {
          url
        }
        seo {
          social {
            instagram
            twitter
          }
        }
        slug
      }
    }
  }
`;

const AuthorSiteTemplate = (props: AuthorSiteTemplateProps): JSX.Element => {
  const { data, pageContext } = props;
  const { classes } = useAuthorSiteTemplateStyles();

  const user = data.users?.nodes[0] || [];

  const instagramLink: string = user.seo.social.instagram;
  const twitterLink: string = user.seo.social.twitter;

  // console.log(twitterLink);

  return (
    <Grid
      container={true}
      direction="column"
      alignItems="center"
      item={true}
      xs={12}
      mb={2.5}
      mt={2.5}
    >
      <SeoBasics
        description={`Hier findest du alle Informationen zur Autor*in  ${user.firstName} ${user.lastName}`}
        title={`politikjam | ${user.firstName} ${user.lastName}`}
      />

      <Typography mb={2} variant="h1">
        {user.firstName} {user.lastName}
      </Typography>

      <Avatar
        alt="Remy Sharp"
        src={user.avatar.url}
        sx={{ width: 250, height: 250 }}
      >
        PA
      </Avatar>

      <Typography mt={1} variant="h4">
        Autor*in
      </Typography>

      <Box>
        {/* nur anzeigen, wenn twitterLink & instagramLink gefüllt sind


         */}

        {!!twitterLink && (
          <MuiLink
            component={GatsbyLink}
            to={`https://twitter.com/${twitterLink}`}
          >
            <TwitterIcon className={classes.marginRight} />
          </MuiLink>
        )}

        {!!instagramLink && (
          <MuiLink component={GatsbyLink} to={`${instagramLink}`}>
            <InstagramIcon className={classes.marginLeft} />
          </MuiLink>
        )}
      </Box>

      <Typography
        mt={1}
        variant="body1"
        className={classes.autorDescription}
        textAlign="center"
      >
        {user.description}
      </Typography>
    </Grid>
  );
};

export default AuthorSiteTemplate;
